ExtUtils.setPlugins([require('/home/avril/avril.2test.ca/releases/20240805124518/packages/amastyCollector/src/plugin/AddListener.plugin.js').default,
require('/home/avril/avril.2test.ca/releases/20240805124518/packages/amastyCollector/src/plugin/AmastyInit.plugin.js').default,
require('/home/avril/avril.2test.ca/releases/20240805124518/packages/amastyCollector/src/plugin/AmastyOnPDPEnter.plugin.js').default,
require('/home/avril/avril.2test.ca/releases/20240805124518/packages/amastyCollector/src/plugin/ConfigQueryModification.plugin.js').default,
require('/home/avril/avril.2test.ca/releases/20240805124518/packages/gtm/src/plugin/AdditionalRoutes.plugin.js').default,
require('/home/avril/avril.2test.ca/releases/20240805124518/packages/gtm/src/plugin/GTMHocWrapper.plugin.js').default,
require('/home/avril/avril.2test.ca/releases/20240805124518/packages/gtm/src/plugin/StoreModifications.plugin.js').default,
require('/home/avril/avril.2test.ca/releases/20240805124518/packages/gtm/src/plugin/event-dispatches/addToCart.plugin.js').default,
require('/home/avril/avril.2test.ca/releases/20240805124518/packages/gtm/src/plugin/event-dispatches/addToWishlist.plugin.js').default,
require('/home/avril/avril.2test.ca/releases/20240805124518/packages/gtm/src/plugin/event-dispatches/cartItemChangeQuantity.plugin.js').default,
require('/home/avril/avril.2test.ca/releases/20240805124518/packages/gtm/src/plugin/event-dispatches/cartItemRemove.plugin.js').default,
require('/home/avril/avril.2test.ca/releases/20240805124518/packages/gtm/src/plugin/event-dispatches/checkout.plugin.js').default,
require('/home/avril/avril.2test.ca/releases/20240805124518/packages/gtm/src/plugin/event-dispatches/checkoutAddPaymentInfo.plugin.js').default,
require('/home/avril/avril.2test.ca/releases/20240805124518/packages/gtm/src/plugin/event-dispatches/checkoutBegin.plugin.js').default,
require('/home/avril/avril.2test.ca/releases/20240805124518/packages/gtm/src/plugin/event-dispatches/checkoutOption.plugin.js').default,
require('/home/avril/avril.2test.ca/releases/20240805124518/packages/gtm/src/plugin/event-dispatches/impressions.plugin.js').default,
require('/home/avril/avril.2test.ca/releases/20240805124518/packages/gtm/src/plugin/event-dispatches/myAccountLoginRegister.plugin.js').default,
require('/home/avril/avril.2test.ca/releases/20240805124518/packages/gtm/src/plugin/event-dispatches/noMatch.plugin.js').default,
require('/home/avril/avril.2test.ca/releases/20240805124518/packages/gtm/src/plugin/event-dispatches/productClick.plugin.js').default,
require('/home/avril/avril.2test.ca/releases/20240805124518/packages/gtm/src/plugin/event-dispatches/productDetail.plugin.js').default,
require('/home/avril/avril.2test.ca/releases/20240805124518/packages/gtm/src/plugin/event-dispatches/purchase.plugin.js').default,
require('/home/avril/avril.2test.ca/releases/20240805124518/packages/@scandipwa/webpack-i18n-runtime/src/plugin/App.plugin.js').default]);
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import 'Util/Extensions';
import 'Util/Polyfill';
import 'Style/main';
import { addPolyfills } from 'Util/Polyfills';

import { render } from 'react-dom';

import App from 'Component/App';

// let's register service-worker
if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        const swUrl = '/service-worker.js';
        navigator.serviceWorker.register(swUrl, { scope: '/' });
    });
}

addPolyfills();

render(<App />, document.getElementById('root'));
